import { create } from "zustand";

interface StyleStore {
	theme: "Dark" | "Light";
	overrideBackgroundColorLookup: Map<string, string>;
	overrideBackgroundColor: (color: string) => () => void;
	backgroundColorListeners: Record<
		string,
		(overrideBackgroundColor: Map<string, string>) => void
	>;
}
const getRandomString = () => Math.random().toString(36).substring(7);

const useStyleStore = create<StyleStore>((set, get) => ({
	theme: "Dark",
	overrideBackgroundColorLookup: new Map(),
	overrideBackgroundColor: (color) => {
		const id = getRandomString();
		set((state) => {
			state.overrideBackgroundColorLookup.set(id, color);
			const newOverrideGlobalBackgroundColorLookup = new Map(
				state.overrideBackgroundColorLookup,
			);
			const listeners = Object.values(state.backgroundColorListeners);
			for (const listener of listeners) {
				listener(newOverrideGlobalBackgroundColorLookup);
			}
			return {
				overrideBackgroundColorLookup: newOverrideGlobalBackgroundColorLookup,
			};
		});
		return () => {
			set((state) => {
				state.overrideBackgroundColorLookup.delete(id);
				const newOverrideGlobalBackgroundColorLookup = new Map(
					state.overrideBackgroundColorLookup,
				);
				const listeners = Object.values(state.backgroundColorListeners);
				for (const listener of listeners) {
					listener(newOverrideGlobalBackgroundColorLookup);
				}
				return {
					overrideBackgroundColorLookup: newOverrideGlobalBackgroundColorLookup,
				};
			});
		};
	},
	backgroundColorListeners: {},
}));

export default useStyleStore;
