"use client";
import { LazyMotion as FramerMotionLazy } from "framer-motion";
import { ReactNode } from "react";

const loadFeatures = () => import("./features").then((res) => res.default);

const LazyMotion = ({ children }: { children: ReactNode }) => {
	return <FramerMotionLazy features={loadFeatures}>{children}</FramerMotionLazy>;
};
export default LazyMotion;
