"use client";
import type { init as initType } from "@sentry/browser";
import { useEffect } from "react";
import { curEnv } from "src/config";
import clientVersion from "src/constants/clientVersion";
import { NEXT_SENTRY_DSN } from "./constants";

const sentryLazyInit = async () => {
	// @ts-expect-error
	const init = (await import("@sentry/browser/esm/sdk")).init as typeof initType;

	init({
		dsn: NEXT_SENTRY_DSN,
		debug: false,
		tracesSampleRate: 1,
		environment: curEnv,
		enabled: process.env.NODE_ENV !== "development",
		release: clientVersion,
	});
};

export const SentryLazyInitComponent = () => {
	useEffect(() => {
		sentryLazyInit();
	}, []);
	return null;
};

export default sentryLazyInit;
